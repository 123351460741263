import styled from 'styled-components';
import Img01 from '../Assets/Img/mre-at-millies-01.jpg';
import Img02 from '../Assets/Img/mre-fountain-02.jpg';
import Img03 from '../Assets/Img/mre-landing-03.jpg';
import { useState } from 'react';
import Img04 from '../Assets/Img/mre-with-kid.jpg';
import { useEffect } from 'react';

const carouselImgs = [
	{
		id: 0,
		imgUrl: Img01,
		title: 'Mr. E standing infront of a brick wall.',
		qoute:
			'"I want to touch bases with you all out there. There is hope for you. Give yourself a chance."',
		top: '75%',
		width: '250px',
	},
	{
		id: 1,
		imgUrl: Img02,
		title: 'Mr. E standing in front of a fountain.',
		qoute: `"With hammer in hand, all things must stand. That is the bricklayers' motto."`,
		top: '70%',
		width: '90%',
	},
	{
		id: 2,
		imgUrl: Img03,
		title: "Mr. E waiting in line at Arthur Bryant's BBQ.",
		qoute:
			'"I am at this point still serving others, that I feel need me. I want to make a difference wherever I go."',
		top: '65%',
		width: '90%',
	},
	{
		id: 3,
		imgUrl: Img04,
		title: 'Mr. E at a book event for children.',
		qoute:
			'"I figured the thing that I did not get to do was the things I was meant to do. To be a blessing in disguise, that is what I always wanted to be."',
		top: '60%',
		width: '90%',
	},
];

const LandingSection = styled.section`
	height: 85vh;
	width: 90%;
	background-color: #02082680;
	margin-top: 75px;

	@media (max-width: 800px) {
		width: 95%;
	}
`;

const ImgCarousel = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	overflow: hidden;
`;

const Slide = styled.div`
	height: 100%;
	width: 100%;
	background-position: 0 35%;
	background-size: cover;
	background-repeat: no-repeat;

	@media (max-width: 1000px) {
		background-position: 15% 0;
	}

	@media (max-width: 800px) {
		background-position: 45% 10%;
	}
`;

const QuoteWrapper = styled.div`
	position: absolute;
	left: 5%;
	max-width: 250px;
	padding: 15px;
	background-color: ${(props) => props.theme.colors.tertiaryOpaque};
	border-radius: 5px;
	box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.75);

	@media (max-width: 800px) {
		left: 0;
		height: fit-content;
		max-width: 100%;
		margin: 0 5% 5px 5%;
		padding: 4px 5px;
		text-align: center;
		background-color: ${(props) => props.theme.colors.tertiaryClear};
	}
`;

const Quote = styled.p`
	font-size: 1.5rem;
	color: ${(props) => props.theme.colors.paragraph};
	text-shadow: 0.5px 0.5px 0.75px black;

	@media (max-width: 800px) {
		filter: brightness(175%);
	}
`;

const ArrowWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 7.51533333333%;
	bottom: 2.5%;
	border: 3px solid ${(props) => props.theme.colors.buttonText};
	z-index: 1;
`;

const LeftArrow = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2px;
	color: ${(props) => props.theme.colors.buttonText};
	font-size: 2rem;
	cursor: pointer;
	z-index: 1;
	transition: all 0.25s ease-out;

	&:hover {
		color: ${(props) => props.theme.colors.highlight};
		transform: scale(1.3);
	}

	&:active {
		transform: scale(0.8);
		color: ${(props) => props.theme.colors.button};
	}
`;

const RightArrow = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2px;
	color: ${(props) => props.theme.colors.buttonText};
	font-size: 2rem;
	cursor: pointer;
	z-index: 1;
	transition: all 0.25s ease-out;

	&:hover {
		color: ${(props) => props.theme.colors.highlight};
		transform: scale(1.3);
	}

	&:active {
		transform: scale(0.8);
		color: ${(props) => props.theme.colors.button};
	}
`;

const Svg = styled.svg`
	opacity: 95%;
	position: absolute;
	bottom: -0.5%;
	right: 0;
	width: 100%;
	height: 30%;
	@media (max-width: 650px) {
		opacity: 0%;
	}
`;

const Polygon = styled.polygon`
	fill: ${(props) => props.theme.colors.tertiaryOpaque};
`;

const LandingCarousel = () => {
	const [activeSlide, setActiveSlide] = useState(0);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [windowHight, setWindowHight] = useState(window.innerHeight);

	const navPrev = () => {
		const isFirst = activeSlide === 0;
		const newActiveSlide = isFirst ? carouselImgs.length - 1 : activeSlide - 1;
		setActiveSlide(newActiveSlide);
	};

	const navNext = () => {
		const isLast = activeSlide === carouselImgs.length - 1;
		const newActiveSlide = isLast ? 0 : activeSlide + 1;
		setActiveSlide(newActiveSlide);
	};

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
			setWindowHight(window.innerHeight);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<LandingSection>
			<ImgCarousel>
				<Slide
					style={{
						backgroundImage: `url(${carouselImgs[activeSlide]?.imgUrl})`,
					}}
					alt={carouselImgs[activeSlide]?.title}
				/>
				<QuoteWrapper
					style={{
						width:
							windowWidth <= 650 || windowHight <= 350
								? `${carouselImgs[activeSlide]?.width}`
								: '30%',
						top:
							windowWidth <= 650 ? `${carouselImgs[activeSlide]?.top}` : '25%',
					}}
				>
					<Quote>{`${carouselImgs[activeSlide].qoute}`}</Quote>
				</QuoteWrapper>
				<ArrowWrapper>
					<LeftArrow onClick={navPrev}>
						<i className='fa-solid fa-chevron-left'></i>
					</LeftArrow>
					<RightArrow onClick={navNext}>
						<i className='fa-solid fa-chevron-right'></i>
					</RightArrow>
				</ArrowWrapper>

				{/* <CarouselNav>
					{carouselImgs.map((img, index) => {
						return (
							<Dot key={img.id} onClick={() => navToImage(index)}>
								<i class='fa-solid fa-circle'></i>
							</Dot>
						);
					})}
				</CarouselNav> */}

				<Svg
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 100 100'
					preserveAspectRatio='none'
				>
					<Polygon points='100, 100 100, 65 0 , 100' />
				</Svg>
			</ImgCarousel>
		</LandingSection>
	);
};
export default LandingCarousel;
