import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from 'styled-components';
import Theme from './Components/Theme';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Router>
			<ThemeProvider theme={Theme}>
				<App />
			</ThemeProvider>
		</Router>
	</React.StrictMode>
);
