import styled from 'styled-components';
import img01 from '../Assets/Img/mre-ringing-bell_cleanup.jpg';
import img02 from '../Assets/Img/cross-streets.jpg';
import img03 from '../Assets/Img/the-hands-that-hold.jpg';
import img04 from '../Assets/Img/the-book-about-the-man.jpg';
import img05 from '../Assets/Img/mr-e-smiling-at-house.jpg';
import img06 from '../Assets/Img/mr-e-with-boy-log-look.jpg';
import img07 from '../Assets/Img/mr-e-with-boy-tree.jpg';
import img08 from '../Assets/Img/mre-landing-01.jpg';
import bwimg01 from '../Assets/Img/mre-from-childhood.jpg';
import bwimg02 from '../Assets/Img/young-mre.jpg';
import bwimg03 from '../Assets/Img/mre-hand-drawn.jpg';

const AboutStoryImageWrapper = styled.div`
	width: 80%;
	max-width: 800px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin-bottom: 50px;
`;

const AboutStoryImage = styled.img`
	height: auto;
	min-height: 450px;
	max-height: 750px;
	width: 50%;
	min-width: 350px;
	border-radius: 15px;
	object-fit: cover;
	object-position: top;
	flex: 1;

	@media (max-width: 500px) {
		width: 90%;
		height: auto;
		min-height: 450px;
	}
`;

const AboutStoryImage2Row = styled.img`
	height: 450px;
	min-width: 350px;
	border-radius: 15px;
	object-fit: cover;
	object-position: top;
	flex: 2;

	@media (max-width: 500px) {
		width: 90%;
		height: auto;
		min-height: 450px;
	}
`;

const AboutStoryWrapper = styled.main`
	margin: 100px 0 50px 0;
	min-height: 80vh;
	width: 90%;
	max-width: 950px;
	background-color: ${(props) => props.theme.colors.paragraph};
	border-radius: 15px;
	padding: 50px 100px;
	overflow: hidden;
	position: relative;

	@media (max-width: 800px) {
		width: 95%;
	}

	@media (max-width: 650px) {
		padding: 25px 50px;
	}
`;

const AboutStory = styled.p`
	line-height: 2.25rem;
	font-size: 1.5rem;
	color: ${(props) => props.theme.colors.background};
	text-shadow: 0.5px 0.5px 0.75px black;
	text-indent: 50px;

	@media (max-width: 800px) {
		font-size: 1.25rem;
		line-height: 1.5rem;
		text-indent: 25px;
	}
`;

const AboutPage = () => {
	return (
		<>
			<AboutStoryWrapper>
				<AboutStory>
					Hello there, my name is Edward E. Whitelaw, but you can call me Mr. E,
					and I invite you on a deeply personal and transformative journey in my
					memoir, "2605 House of the Revolving Door". My tale starts back in
					1964, a year when my world was changed forever by the devastating loss
					of my dear mother and grandmother. Overwhelmed with grief, I spiraled
					into a life of crime, lost and filled with bitterness.
				</AboutStory>
				<br />
				<AboutStory>
					In those troubled times, I turned my back on my humble job of washing
					clothes in the laundry at St. Joseph Hospital, immersing myself in the
					thrill of the streets and the company of those on the wrong side of
					the law. Anger at God consumed me, as I wrestled with why such
					tragedies had to befall me. Yet, a spark of resilience, a refusal to
					be defeated lingered within me.
				</AboutStory>
				<br />
				<AboutStory>
					In the midst of my darkest days, I stumbled upon bricklaying. This
					craft introduced me to a community of skilled craftsmen who accepted
					and supported me. Lloyd and Lawrence Johnson, Albert Bowers, William
					Franklin, and many more not only taught me their trade but also helped
					me lay the foundation to rebuild my life.
				</AboutStory>
				<br />
				<AboutStory>
					My journey is not only about masonry and bricklaying, but also about
					the power of family and friends. My sister Grace and niece Jackie were
					beacons of support, offering me strength and love when I needed them
					the most. Encouraged by their belief in me, I turned my life around,
					starting a food pantry ministry to feed and serve those less
					fortunate.
				</AboutStory>
				<br />
				<AboutStory>
					As you navigate the peaks and valleys of my life, you'll meet
					individuals who left indelible marks on me. From the lovable Charles
					Franklin (aka C) and Frank Lindsey to the guiding figures like lawyers
					Joe Moore and Clifford Spottsfield, each person played their part in
					shaping my journey.
				</AboutStory>
				<br />
				<AboutStory>
					In "2605 House of the Revolving Door", my tale unravels as one of
					hope, faith, and the profound impact of serving others. Through it
					all, I've learned to trust in God, follow my dreams, and never give
					up, no matter how tough the odds. I hope my story inspires you to face
					your own battles, pursue your passions, and never forget the power of
					kindness and service to others. Thank you for joining me on this
					remarkable journey.
				</AboutStory>
			</AboutStoryWrapper>
			<AboutStoryImageWrapper>
				<AboutStoryImage
					src={img06}
					alt='Mr. E and a boy looking into a hallowed out log.'
				></AboutStoryImage>
				<AboutStoryImage
					src={img01}
					alt='Mr. E singing in church while ringing a bell.'
				></AboutStoryImage>
				<AboutStoryImage
					src={img02}
					alt='The crossroads of 26th St and Indiana. The streets Mr. E grew up on'
				></AboutStoryImage>
				<AboutStoryImage
					src={img03}
					alt="Mr. E's hands adorned with jeawlrey"
				></AboutStoryImage>
				<AboutStoryImage
					src={img07}
					alt='Mr. E and a boy posing in front of a tree.'
				></AboutStoryImage>
				<AboutStoryImage
					src={img04}
					alt='Mr. E holding up his book at a book signing event.'
				></AboutStoryImage>
				<AboutStoryImage
					src={bwimg02}
					alt='Mr. E when he was in highschool'
				></AboutStoryImage>
				<AboutStoryImage
					src={img05}
					alt='Mr. E and a boy posing in front of a log.'
				></AboutStoryImage>
				<AboutStoryImage
					src={img08}
					alt='Mr. E and his sisters from when they were children.'
				></AboutStoryImage>
				<AboutStoryImage
					src={bwimg01}
					alt='Mr. E and his sisters from when they were children.'
				></AboutStoryImage>
				<AboutStoryImage2Row
					src={bwimg03}
					alt='A hand drawn picture of 4 people smiling. In a cartoon style.'
				></AboutStoryImage2Row>
			</AboutStoryImageWrapper>
		</>
	);
};
export default AboutPage;
