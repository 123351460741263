import styled from 'styled-components';
import Logo from './Logo';
import { Link } from 'react-router-dom';

const Heading = styled.header`
	background-color: ${(props) => props.theme.colors.background};
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 15px;
	margin-bottom: 25px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
`;

const Header = () => {
	return (
		<Heading className='heading'>
			<Link to='/'>
				<Logo />
			</Link>
		</Heading>
	);
};
export default Header;
