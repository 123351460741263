import styled from 'styled-components';
import aboutImg from '../Assets/Img/mre-about.png';
import { Link } from 'react-router-dom';

const About = () => {
	const AboutSection = styled.section`
		min-height: 80vh;
		width: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		background-color: ${(props) => props.theme.colors.tertiary};
		position: relative;
		margin-bottom: 50px;

		@media (max-width: 800px) {
			width: 95%;
		}
	`;

	const AboutTitle = styled.h3`
		text-align: center;
		width: 80%;
		color: ${(props) => props.theme.colors.background};
		margin: 5% 0;
		font-size: 2.25rem;
	`;

	const AboutParagraphWrapper = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 80%;

		@media (max-width: 650px) {
			width: 95%;
			margin-bottom: 235px;
		}
	`;

	const AboutParagraph = styled.p`
		color: ${(props) => props.theme.colors.paragraph};
		width: 50%;
		margin-left: 35%;
		margin-bottom: 25px;
		line-height: 2.75rem;
		z-index: 2;
		font-size: 2rem;
		padding: 15px;
		text-shadow: 0.5px 0.5px 0.75px black;
		background-color: ${(props) => props.theme.colors.headlineOpaque};
		border-radius: 15px;

		@media (max-width: 1600px) {
			margin-left: 0;
			width: 80%;
			margin-bottom: 40%;
		}

		@media (max-width: 1000px) {
			width: 100%;
		}

		@media (max-width: 650px) {
			filter: brightness(150%);
			margin-bottom: 25px;
			line-height: 2rem;
			font-size: 1.25rem;
			letter-spacing: 0.022rem;
		}
	`;

	const ReadMore = styled(Link)`
		color: ${(props) => props.theme.colors.highlight};
		margin-left: 5px;
	`;

	const AboutImg = styled.div`
		display: flex;
		position: absolute;
		margin-left: 20%;
		bottom: -45px;
		width: 100%;
		img {
			word-break: break-word;
			height: 100%;
			max-width: 100%;
			height: auto;
		}

		@media (max-width: 590px) {
			justify-content: center;
			bottom: -35px;
			margin-left: 0;
		}
	`;

	return (
		<AboutSection>
			<AboutParagraphWrapper>
				<AboutTitle>Mr. Edward E. Whitelaw aka Young Ole School</AboutTitle>
				<AboutParagraph>
					Hello there, my name's Mr. E. My life's been an extraordinary journey,
					starting as a humble laundry worker and blooming into a skilled
					bricklayer, cook, community activist, and much much more. It hasn't
					been without its bumps, marked by personal tragedies and even a
					regretful detour into crime. But it all ultimately led me to
					rediscover my purpose in serving others. Thanks to my unwavering faith
					and the support of my dear loved ones, I'm proud to say that I've
					become an inspiration, etching out a lasting legacy of resilience,
					compassion, and the steadfast pursuit of one's dreams.
					<ReadMore to='/about'>Read More...</ReadMore>
				</AboutParagraph>
			</AboutParagraphWrapper>
			<AboutImg>
				<img src={aboutImg} alt='Mr. E sitting on a park bench.' />
			</AboutImg>
		</AboutSection>
	);
};
export default About;
