import styled from 'styled-components';
import './App.css';
import Header from './Components/Header';

import { Routes, Route } from 'react-router-dom';
import HomePage from './Components/HomePage';
import AboutPage from './Components/AboutPage';

const Page = styled.main`
	background-color: ${(props) => props.theme.colors.background};
	min-height: 100vh;
	min-width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
	position: relative;
	overflow-x: hidden;
`;

function App() {
	return (
		<Page className='App'>
			<Header />
			<Routes>
				<Route path='/' element={<HomePage />} />
				<Route path='/about' element={<AboutPage />} />
			</Routes>
		</Page>
	);
}

export default App;
