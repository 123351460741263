import Landing from './Landing';
import About from './About';
import SaleHighlight from './SaleHighlight';
import Contact from './Contact';

const HomePage = () => {
	return (
		<>
			<Landing />
			<About />
			<SaleHighlight />
			<Contact />
		</>
	);
};
export default HomePage;
