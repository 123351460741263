import axios from 'axios';
import { useState } from 'react';
import styled from 'styled-components';
import toast, { Toaster } from 'react-hot-toast';

const ContactSection = styled.section`
	width: 60%;
	display: flex;
	flex-wrap: wrap-reverse;
	align-items: start;
	justify-content: center;
	margin-bottom: 50px;

	@media (max-width: 665px) {
		width: 320px;
	}
`;

const ContactPitchWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 50%;

	@media (max-width: 1000px) {
		width: 95%;
		margin-bottom: 25px;
	}
`;

const ContactTitle = styled.h2`
	color: ${(props) => props.theme.colors.headline};
	margin-bottom: 15px;
`;

const ContactPitch = styled.p`
	width: 75%;
	line-height: 1.5rem;
	font-size: 1.25rem;
	color: ${(props) => props.theme.colors.paragraph};

	@media (max-width: 1000px) {
		width: 100%;
	}
`;

const ContactFormWrapper = styled.div`
	width: 100%;
	max-width: 300px;
`;

const ContactForm = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 95%;
	margin-top: 15px;
`;

const Label = styled.label`
	width: 100%;
	margin: 5px 0;
`;

const SubmitButtonReady = styled.button`
	background-color: ${(props) => props.theme.colors.button};
	color: ${(props) => props.theme.colors.buttonText};
	border-radius: 15px;
	padding: 10px 25px;
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 600;
	transition: all 0.25s ease-out, active 0.15s ease-in;
	margin: 15px auto;

	&:hover {
		background-color: ${(props) => props.theme.colors.buttonText};
		color: ${(props) => props.theme.colors.button};
		transform: scale(1.1);
		border-radius: 5px;
	}

	&:active {
		transform: scale(0.8);
		border-radius: 15px;
	}
`;

const SubmitButtonNotReady = styled.button`
	background-color: ${(props) => props.theme.colors.button};
	color: ${(props) => props.theme.colors.buttonText};
	border-radius: 15px;
	padding: 10px 25px;
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 600;
	transition: all 0.25s ease-out, active 0.15s ease-in;
	margin: 15px auto;
	cursor: not-allowed;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		inset: 0;
		background-color: #80808075;
		border-radius: 15px;
	}
`;

const Contact = () => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		message: '',
	});

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		axios.defaults.headers.post['Content-Type'] = 'application/json';
		axios
			.post('https://formsubmit.co/ajax/0e2b709028bbc0ba2eefd334f2a78792', {
				name: `FormSubmit email from ${formData.name}, origin: Mr. E from KC`,
				message: `A new message from ${formData.name} @${formData.email}: ${formData.message}`,
			})
			.then((response) => {
				toast.success(`${response.data.message}`);
				console.log(response);
			})
			.catch((error) => toast.error(error.message));

		setFormData({
			name: '',
			email: '',
			message: '',
		});
	};

	return (
		<ContactSection>
			<ContactFormWrapper>
				<ContactForm onSubmit={handleSubmit}>
					<Label htmlFor='name'>Name:</Label>
					<input
						type='text'
						name='name'
						value={formData.name}
						onChange={handleChange}
					/>
					<Label htmlFor='email'>Email:</Label>
					<input
						type='email'
						name='email'
						value={formData.email}
						onChange={handleChange}
					/>
					<Label htmlFor='message'>Message:</Label>
					<textarea
						name='message'
						value={formData.message}
						onChange={handleChange}
					/>
					{!formData.name || !formData.email || !formData.message ? (
						<SubmitButtonNotReady type='none' disabled>
							Submit
						</SubmitButtonNotReady>
					) : (
						<SubmitButtonReady type='submit'> Submit </SubmitButtonReady>
					)}
				</ContactForm>
			</ContactFormWrapper>
			<ContactPitchWrapper>
				<ContactTitle> Contact Me </ContactTitle>
				<ContactPitch>
					If you have questions about purchasing a physical copy of 2605 House
					of the Revolving Door or have stories to share, Young Ole School
					welcomes your correspondence. He appreciates meaningful interactions
					and is eager to engage in thought-provoking discussions. Your thoughts
					and perspectives are valued, and Young Ole School looks forward to
					connecting with you.
				</ContactPitch>
			</ContactPitchWrapper>
			<Toaster />
		</ContactSection>
	);
};
export default Contact;
