const Theme = {
	colors: {
		background: '#f9f8f8',
		logo: '#343738',
		headline: '#0F0C04',
		headlineOpaque: '#0f0c0434',
		paragraph: '#998257',
		paragraphClear: '#99825740',
		button: '#47211B',
		buttonText: '#8c7851',
		secondary: '#8c7851',
		secondaryOpaque: '#8c785122',
		tertiary: '#47211B',
		tertiaryOpaque: '#47211bea',
		tertiaryClear: '#47211b80',
		highlight: '#2DC4A9',
		white: '#FFFFF0',
	},
};
export default Theme;
