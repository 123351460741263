import styled from 'styled-components';
import bookImg from '../Assets/Img/mre-book-front.jpg';
import { useState } from 'react';

const SaleHighlightSection = styled.section`
	min-height: 60vh;
	width: 90%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	place-content: center;
	margin-bottom: 50px;

	@media (max-width: 800px) {
		width: 95%;
	}
`;

const BookWrapper = styled.div`
	place-self: center;
	max-height: 370px;
	margin: 0 auto;
	box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.75);
	border-radius: 5px;
	margin-bottom: 50px;
`;

const Book = styled.img`
	max-height: 370px;
	width: 250px;
	border-radius: 8px;
`;

const PitchWrapper = styled.div`
	margin: 0 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-width: 865px) {
		margin: 0 0 50px 0;
	}
`;

const PitchHeading = styled.h3`
	font-size: 1.15rem;
	color: ${(props) => props.theme.colors.headline};
	margin-bottom: 5px;
`;

const PitchSubHeading = styled.h6`
	font-size: 0.8rem;
	color: ${(props) => props.theme.colors.headlineOpaque};
	margin-bottom: 5px;
`;

const PitchTitle = styled.h2`
	font-size: 2.25rem;
	color: ${(props) => props.theme.colors.headline};
	margin-bottom: 15px;
	text-align: center;
`;

const Pitch = styled.p`
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: ${(props) => props.theme.colors.paragraph};
	text-align: center;
	width: 100%;
`;

const BuyButton = styled.button`
	background-color: ${(props) => props.theme.colors.button};
	color: ${(props) => props.theme.colors.buttonText};
	border-radius: 15px;
	padding: 10px 25px;
	margin-top: 50px;
	/* width: 150px; */
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 600;
	transition: all 0.25s ease-out, active 0.15s ease-in;
	cursor: pointer;

	&:hover {
		background-color: ${(props) => props.theme.colors.buttonText};
		color: ${(props) => props.theme.colors.button};
		transform: scale(1.1);
		border-radius: 5px;
	}

	&:active {
		transform: scale(0.8);
		border-radius: 15px;
	}
`;

const CancelButton = styled.button`
	background-color: ${(props) => props.theme.colors.white};
	color: ${(props) => props.theme.colors.buttonText};
	border-radius: 15px;
	padding: 10px 25px;
	/* width: 150px; */
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 600;
	transition: all 0.25s ease-out, active 0.15s ease-in;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
		border-radius: 5px;
	}

	&:active {
		transform: scale(0.8);
		border-radius: 15px;
	}
`;

const ConfirmationWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	background-color: ${(props) => props.theme.colors.tertiaryOpaque};
	height: 100%;
	width: 100%;
	z-index: 4;
	display: grid;
	gap: 20px;
	place-items: center;
	padding: 20% 30%;
	overflow: scroll;

	@media (max-width: 650px) {
		padding: 30% 5% 10% 50px;
	}
`;

const DisclaimerTitle = styled.h2`
	font-size: 2.25rem;
	color: ${(props) => props.theme.colors.white};
	margin-bottom: 15px;
	text-align: center;
`;

const SaleHighlight = () => {
	const [showConfirm, setShowConfirm] = useState(false);

	const redirectToPurchase = () => {
		setShowConfirm(false);
		window.open('http://www.amazon.com/dp/B0CDSZZNSV');
	};

	return (
		<SaleHighlightSection>
			<PitchWrapper>
				<PitchHeading>Available Now!!!</PitchHeading>
				<PitchSubHeading>Only In E-Book</PitchSubHeading>
				<PitchTitle>
					2605:
					<br />
					House of the Revolving Door
				</PitchTitle>
				<Pitch>
					"2605 House of the Revolving Door" is a compelling memoir that
					showcases the power of faith, perseverance, and the impact of serving
					others. It is a testament to the transformative nature of personal
					growth, as the author's story serves as an inspiration for readers to
					overcome their own challenges and find strength in their journey
					towards a brighter future.
				</Pitch>
				<BuyButton onClick={() => setShowConfirm(true)}>Buy Now!!!</BuyButton>
			</PitchWrapper>
			<BookWrapper>
				<Book
					src={bookImg}
					alt='Cover of 2605 The House of the Revolving Door.'
				/>
			</BookWrapper>

			{showConfirm && (
				<ConfirmationWrapper>
					<DisclaimerTitle>E-Book Purchase Disclaimer</DisclaimerTitle>
					<Pitch>
						Please note: This website exclusively offers the e-book variation of
						2605 House of the Revolving Door. No physical copies are available
						for purchase through this platform. By proceeding with your
						purchase, you acknowledge that you are buying a digital format of
						the book, which will be delivered electronically. Ensure your device
						is compatible with Amazon/Kindle e-books. If you wish to purchase a
						paper back direct from the author please use the contact form at the
						bottom of the website to reach out about your intrest. Thank you for
						understanding.
					</Pitch>
					<BuyButton onClick={() => redirectToPurchase()}>Buy Now!!!</BuyButton>
					<CancelButton onClick={() => setShowConfirm(false)}>
						Cancel
					</CancelButton>
				</ConfirmationWrapper>
			)}
		</SaleHighlightSection>
	);
};
export default SaleHighlight;
