import styled from 'styled-components';

const Logo = () => {
	const LogoWrapper = styled.div`
		display: flex;
		border-bottom: 1px solid ${(props) => props.theme.colors.logo};
	`;

	const From = styled.span`
		padding: 0 5px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.25rem;
		color: ${(props) => props.theme.colors.logo};
	`;
	const MrELogoText = styled.h1`
		font-family: 'Playfair', serif;
		font-size: 3.5rem;
		color: ${(props) => props.theme.colors.logo};
	`;

	return (
		<LogoWrapper>
			<MrELogoText> Mr. E </MrELogoText>
			<From>from</From>
			<MrELogoText>KC</MrELogoText>
		</LogoWrapper>
	);
};
export default Logo;
